/* eslint-disable react/no-danger */
import {
  useGlobals,
  useFocusTrap,
  useOnClickOutside,
  useDisableScroll,
} from '@bwoty-web/ui-kit/hooks';
import { UI_CROSS, UI_MENU, UI_CHEVRON_RIGHT } from '@bwoty-web/ui-kit/icons';
import { Icon } from '@bwoty-web/ui-kit';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useState, useRef, useEffect } from 'react';
import SubMenu from './SubMenu';
import './menu.scss';

const Menu = ({ isOpen }) => {
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const [activeItems, setActiveItems] = useState([]);
  const overlayRef = useRef(null);
  const containerRef = useRef(null);
  const { content } = useGlobals();

  useEffect(() => {
    if (!isOpenState) return undefined;

    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') setIsOpenState(false);
    };

    window.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isOpenState]);

  useDisableScroll(isOpenState);
  useFocusTrap(overlayRef, isOpenState);
  useOnClickOutside(containerRef, () => setIsOpenState(false), isOpenState);

  return (
    <>
      <button
        type="button"
        id="header-menu-button"
        className="header-menu-button"
        aria-expanded={isOpenState}
        onClick={() => setIsOpenState(true)}
      >
        <Icon
          path={UI_MENU}
          size="s"
          className="header-hamburger-icon"
          aria-hidden="true"
          strokeWidth="2"
        />
        <span className="visually-hidden">{content.menuLabel}</span>
      </button>
      <div
        ref={overlayRef}
        className={cx('header-menu-overlay', {
          'header-menu-overlay--active': isOpenState,
        })}
      >
        <div
          ref={containerRef}
          className={cx('header-menu-container', {
            [`header-menu-container--level-${activeItems.length}`]:
              activeItems.length > 0,
          })}
        >
          <div className="header-menu-links">
            <ul className="header-main-menu">
              {content.mainLinks.map((mainLink) => {
                const { link, subLinks, title, id } = mainLink;
                const hasSubLinks = subLinks?.length > 0;

                if (hasSubLinks) {
                  const isExpanded = activeItems.find(item => item.id === id);
                  return (
                    <li
                      className={cx('header-main-menu-item', {
                        'header-main-menu-item--expanded': isExpanded,
                      })}
                      key={id}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          setActiveItems([{title, id}])
                        }
                      >
                        {title}
                        <Icon path={UI_CHEVRON_RIGHT} size="xxs" aria-hidden />
                      </button>
                      <SubMenu
                        items={subLinks}
                        activeItems={activeItems}
                        setActiveItems={setActiveItems}
                        content={content}
                        parentId={id}
                      />
                    </li>
                  );
                }
                return (
                  <li className="header-main-menu-item" key={link?.title}>
                    <a href={link?.href?.url}>{link?.title}</a>
                  </li>
                );
              })}
            </ul>
            <div className="header-menu-divider" />
            <ul className="header-secondary-menu">
              {content.secondaryLinks.map((secondaryLink) => (
                <li key={secondaryLink.title}>
                  <a href={secondaryLink.href.url}>{secondaryLink.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <button
            onClick={() => {
              setIsOpenState(false);
            }}
            type="button"
            className="header-menu-close-button"
          >
            <Icon path={UI_CROSS} size="s" />
            <span className="visually-hidden">{content.closeMenuLabel}</span>
          </button>
        </div>
      </div>
    </>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
};

Menu.defaultProps = {
  isOpen: false,
};

export default Menu;

/* eslint-disable no-undef */
import { render } from 'react-dom';
import { Globals } from '@bwoty-web/ui-kit';
import Menu from '../shared/header/components/menu/Menu';

const initialStateHeader = window.__INITIAL_STATE_HEADER__;
// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = `${initialStateHeader?.baseUrl}/static/`;

delete window.__INITIAL_STATE_HEADER__;
const menuRoot = document.getElementById('header-menu');

if (menuRoot) {
  render(
    <Globals {...initialStateHeader}>
      <Menu isOpen={!!menuIsLoadedByClick} />
    </Globals>,
    menuRoot,
  );
}
